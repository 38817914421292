import { useEffect } from "react";

const useScript = (url, id, fireScript, async) => {
  return useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = async;
    script.id = id;

    if (fireScript) {
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    } else {
      return;
    }
  }, [url, id, fireScript, async]);
};

export default useScript;