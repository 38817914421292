import { useOnMount } from "./index";
import { isUndefined } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/loanForm/actions";

export default (fieldName, defaultValue = "") => {
  const dispatch = useDispatch();
  const { initialValue } = useSelector(state => state.loanForm);
  const value = initialValue[fieldName]

  useOnMount(() => {
    if (isUndefined(value) || value === null) {
      dispatch(actions.setFieldInitialValue({[fieldName]: defaultValue}));
    }
  });
};