import actions from "./actions";

const initState = {
  value: {},
  moneyspotData: {},
  isLoading: false,
  referrerName: "",
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.SET_STATUS_DATA:
      return {
        ...state,
        value: action.payload,
        referrerName: action.payload.referrer,
      };
    case actions.MONEYSPOT_ENQUIRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        moneyspotData: action.payload,
      };
    case actions.SUBMIT_MONEYSPOT_ENQUIRY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.MONEYSPOT_ENQUIRY_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.FETCH_STATUS_DATA:
      return {
        ...state,
      };
    case actions.FORCE_LEAD_MARKET:
      return {
        ...state,
        referrerName: "LeadMarket",
      };
    default:
      return state;
  }
}
