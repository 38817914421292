import styled from "styled-components";
import { palette, size } from "styled-theme";
import { isIE11 } from "../../utils/helpers/browserHelper";

const Wrapper = styled.div`
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    
    z-index: 999;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 100px;
    padding-bottom: 50px;
    backdrop-filter: blur(0px);
    
    @media only screen and (max-width: ${size("M")}) {
      padding-top: 20px;
      padding-bottom: 10px;
    }
    
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    background-color: ${palette("modal", 2)};
    &.ReactModal__Overlay--after-open {
      background-color: rgba(136, 136, 136, 0.43); /* IE11 */
      background-color: ${palette("grayscale", 12)};
      backdrop-filter: blur(10px);
    }
    &.is-closing {
      background-color: ${palette("modal", 2)};
      backdrop-filter: blur(0px);
    }
  }
  
  .modal-container {
    display: flex;
  }

  .modal-action-button {
    margin: 20px 0 0;
    align-self: center;
    width: 80%;
  }

  .modal-container, .modal-content {
    max-height: 100%;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${palette("color", 16)};
    text-align: left;
    border: 1px solid #888;
    font-size: ${size("p5")};
    box-shadow: 0 4px 8px 0 ${palette("grayscale", 12)},
      0 6px 20px 0 ${palette("grayscale", 12)};
    border-radius: 9px;
    
    @media only screen and (max-width: ${size("M")}) {
      max-width: calc(100vw - 40px) !important;
    }
    
    ${isIE11 && `
      max-height: calc(100vh - 100px);
    `}
    
    &.extra-small{
      max-width: 358px;
      /* IE11 max-width px fix */
      flex: 1 0 358px;
    }
    
    &.small{
      max-width: 500px;
      /* IE11 max-width px fix */
      flex: 1 0 500px;
    }
  
    &.medium{
      max-width: 60vw;
      width: 100%;
    }
    
    &.large{
      max-width: 90vw;
      width: 100%;
    }
    
    p {
      margin-top: ${size("p5")};
      margin-bottom: ${size("p5")};
    }
  }
  
  .modal-Body {
    overflow: auto;
    padding: 8px 16px;
    color: ${palette("color", 17)};
  }

  .modal-Header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: left;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${palette("modal", 3)};
    color: ${palette("color", 16)};
    padding: 20px;
  }

  .modal-Title {
    display: block;
    width: 95%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }

  .modal-Footer {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    padding: 2px 16px;
    background-color: ${palette("color", 16)};
    color: black;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 5px;
    padding-bottom: 25px;
    @media only screen and (max-width: ${size("M")}) {
      padding-bottom: 14px;
    }
  }

  .modal-Close-Icon {
    align-self: center;
    background: none;
    border: none;
    color: ${palette("color", 16)};
    float: right;
    font-size: 24px;
    font-weight: bold;
  }

  .modal-Close-Icon:hover,
  .modal-Close-Icon:focus {
    color: ${palette("color", 21)};
    cursor: pointer;
  }
`;

export default Wrapper;
