import environmentSettings from "../../constants/environmentSettings"

export const isDevelopment = () => {
  return environmentSettings.runConfiguration === 'development'
}

export const isProduction = () => {
  return environmentSettings.runConfiguration === 'production'
}

export const shouldUseAsyncQueue = () => {
  return environmentSettings.shouldUseAsyncQueue === "enabled";
}