import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SUBMIT_ACCEPT_START = "SUBMIT_ACCEPT_START";
const SUBMIT_ACCEPT_FAILED = "SUBMIT_ACCEPT_FAILED";
const SUBMIT_ACCEPT_SUCCESS = "SUBMIT_ACCEPT_SUCCESS";

const actions = {
  SUBMIT_ACCEPT_START,
  SUBMIT_ACCEPT_FAILED,
  SUBMIT_ACCEPT_SUCCESS,
  submitAcceptLiftLoan: createPayloadAction(SUBMIT_ACCEPT_START),
  submitAcceptLiftLoanFailed: createEmptyAction(SUBMIT_ACCEPT_FAILED),
  submitAcceptLiftLoanSuccess: createEmptyAction(SUBMIT_ACCEPT_SUCCESS),
};

export default actions;
