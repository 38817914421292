import { useSelector } from "react-redux";

const useUnSanitisedAccountExternalId = () => {
  const { queryString } = useSelector(state => state.StartUp);
  const { id } = queryString || {};
  const { accountExternalId } = useSelector(state => state.loanForm);

  return id || accountExternalId || "";
};

const useAccountExternalId = () => {
  const accountExternalId = useUnSanitisedAccountExternalId();
  return accountExternalId.replace(/^loanapplication-/, "");
};

export default useAccountExternalId;