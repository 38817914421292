import styled from "styled-components";
import { palette, size, font } from "styled-theme";

export const buttonPadding = 13;

const getButtonColor = (palletIndex) => (props) => palette(`button.${props.$variant || "default"}`, palletIndex)(props);

export const ButtonBox = styled.div`
  background-color: ${getButtonColor(0)};
  color: ${getButtonColor(1)};
  font-size: ${size("p3")};
  font-weight: 500;
  text-transform: uppercase;
  padding: ${buttonPadding}px;
  border-radius: 25px;
  user-select: none; 
  text-align: center;
  display: block;
  box-sizing: border-box;
  font-family: ${font("primary")};
  text-decoration: none;
  
  &::before {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 25px;
    
    /* Create the box shadow at expanded size. */
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.3);
    
    /* Hidden by default. */
    opacity: 0;
    transition: opacity 200ms;
  }
  
  &:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
  }
  &.hover-on::before {
    opacity: 1;
  }
  &.no-shadows:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 0;
  }
  
  
  &::after {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    
    /* Create the box shadow at expanded size. */
    box-shadow: inset 0px 0px 8px rgba(0,0,0,0.2);
    
    /* Hidden by default. */
    opacity: 0;
    transition: opacity 50ms;
  }
  
  &:active::after {
    opacity: 1;
  }
  &.no-shadows:active::after {
    opacity: 1;
  }
`;

export const StyledButton = styled(ButtonBox).attrs((props) => ({
  as: props.$elementType ?? "button",
  "aria-label": props.ariaLabel
}))`
  max-width: 371px;
  width: 100%;
  position: relative;
  border: none;
  cursor: pointer;
  
  
  &:focus {
    outline: none;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.2);
  }
  
  &:active {
    outline: none;
  }
  
  &.disabled {
    background-color: gray;
    opacity: 0.3;
    pointer-events: none;
  }
`;
