import React from "react";
import { useOnMount } from "../../hooks";
import CONFIG from "../../constants/config";
import { emptyFunction } from "../../utils/helpers/emptyFunction";

const fairGoInitMap = "fairGoInitMap";
const placesAutocompleteInit = "initMap";

// react-places-autocomplete deletes it's init function on dismount. So if we get:
//
// MapsApiScriptElement - Mount
// AddressFiled - Mount
// AddressField - UnMount # delete(window.initMap)
// AddressField - Mount
// google api init event # initMap deleted above so ERR
//
// this shim lets us ensure that the init that google calls is never removed
const configureInitCallback = () => {
  window[fairGoInitMap] = () => (window[placesAutocompleteInit] || emptyFunction)();
};

function createScriptElement() {
  const mapScriptElement = document.createElement(`script`);
  mapScriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_API_KEY}&libraries=places&callback=${fairGoInitMap}`;

  return mapScriptElement;
}

function appendScriptElement(mapScriptElement) {
  document
    .querySelector(`body`)
    .insertAdjacentElement(`beforeend`, mapScriptElement);
}

export const MapsApiScriptElement = () => {
  useOnMount(() => {
    configureInitCallback();
    appendScriptElement(createScriptElement());
  });
  
  return <></>;
};