import { useEffect, useState } from "react";

const useTimedToggle = (timeout = 5000) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (shouldShow) {
      const timeoutId = setTimeout(() => {
        setShouldShow(false);
      }, timeout);

      return () => clearTimeout(timeoutId);
    }
  }, [shouldShow, setShouldShow, timeout]);

  const setShowTrue = () => {
    setShouldShow(true);
  };

  return [shouldShow, setShowTrue];
};

export default useTimedToggle;