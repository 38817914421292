import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SUBMIT_ACCEPT_AUTO_FINANCE_START = "SUBMIT_ACCEPT_AUTO_FINANCE_START";
const SUBMIT_ACCEPT_AUTO_FINANCE_FAILED = "SUBMIT_ACCEPT_AUTO_FINANCE_FAILED";
const SUBMIT_ACCEPT_AUTO_FINANCE_SUCCESS = "SUBMIT_ACCEPT_AUTO_FINANCE_SUCCESS";

const actions = {
  SUBMIT_ACCEPT_AUTO_FINANCE_START: SUBMIT_ACCEPT_AUTO_FINANCE_START,
  SUBMIT_ACCEPT_AUTO_FINANCE_FAILED: SUBMIT_ACCEPT_AUTO_FINANCE_FAILED,
  SUBMIT_ACCEPT_AUTO_FINANCE_SUCCESS: SUBMIT_ACCEPT_AUTO_FINANCE_SUCCESS,
  submitAcceptAutoFinance: createPayloadAction(SUBMIT_ACCEPT_AUTO_FINANCE_START),
  submitAcceptAutoFinanceFailed: createEmptyAction(SUBMIT_ACCEPT_AUTO_FINANCE_FAILED),
  submitAcceptAutoFinanceSuccess: createEmptyAction(SUBMIT_ACCEPT_AUTO_FINANCE_SUCCESS),
};

export default actions;
