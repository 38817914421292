import { combineReducers } from "redux";
import StartUp from "./startup/reducer";
import loanForm from "./loanForm/reducer";
import esign from "./esign/reducer";
import acceptLiftLoan from "./acceptLiftLoan/reducer";
import acceptLineOfCredit from "./acceptLineOfCredit/reducer";
import acceptAutoFinance from "./acceptAutoFinance/reducer";
import verify from "./verify/reducer";
import trackingStatus from "./statusTracking/reducer";
import ContentDelivery from "./contentDelivery/reducer";

export const createRootReducer = () => combineReducers({
  StartUp,
  loanForm,
  esign,
  acceptLiftLoan,
  acceptLineOfCredit,
  acceptAutoFinance,
  verify,
  trackingStatus,
  ContentDelivery,
});
