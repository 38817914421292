import { all, takeLatest, call, put } from "redux-saga/effects";
import actions from "./actions";
import { postAcceptAutoFinance } from "../../services/personalLoanApi";

function* submitAcceptAutoFinance({payload}) {
  const { accountExternalId } = payload;
  
  try {
    yield call(postAcceptAutoFinance, accountExternalId);
    yield put(actions.submitAcceptAutoFinanceSuccess());
  } catch(e) {
    yield put(actions.submitAcceptAutoFinanceFailed());
  }
}

export const __TEST__ = {
  submitAcceptAutoFinance
};

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_ACCEPT_AUTO_FINANCE_START, submitAcceptAutoFinance),
  ]);
}
