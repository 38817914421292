import environmentSettings from "../constants/environmentSettings";

export default {
  BASE_URL: environmentSettings.apiManagement,
  LOAN_AMOUNT: "/aps/api/prices",
  AUTO_LOAN_AMOUNT: "/aps/api/prices/auto",
  LOAN_REASONS: "/aps/api/loanReason/getProducts",
  APPLICATION_ENDPOINT: "/aps/api/Application",
  APPLICATION_STATUS_ENDPOINT: "/aps/api/Application/status",
  APPLICATION_QUEUE_ENDPOINT: "/aps/api/applicationToQueue",
  SUBMIT_BANKSTATEMENTS_ENDPOINT: "/aps/api/bankstatements",
  FILE_UPLOAD_ENDPOINT: "/aps/api/FileUpload",
  ESIGN_ENDPOINT: "/aps/api/ESign",
  ACCEPT_LIFT_LOAN_ENDPOINT: "/aps/api/esign/AcceptliftloanOffer",
  ACCEPT_LINE_OF_CREDIT_ENDPOINT: "/aps/api/Application/accountTypeToLoc",
  ACCEPT_AUTO_FINANCE_ENDPOINT: "/aps/api/AutoFinance/Accept",
  FILE_DOWNLOAD: "/aps/api/FileDownload/Account",
};

export const referMeMoneyspot = (accountExternalId) => `/referral/api/Moneyspot/${accountExternalId}`;
