import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SUBMIT_ESIGN_START = "SUBMIT_ESIGN_START";
const SUBMIT_ESIGN_FAILED = "SUBMIT_ESIGN_FAILED";
const SUBMIT_ESIGN_SUCCESS = "SUBMIT_ESIGN_SUCCESS";
const IS_ON_ESIGN_PAGES = "IS_ON_ESIGN_PAGES";
const CURRENT_ESIGN_PAGE_INDEX = "CURRENT_ESIGN_PAGE_INDEX";
const RESET_ESIGN_SUBMIT = "RESET_ESIGN_SUBMIT";
const DOWNLOAD_PDF_START = "DOWNLOAD_PDF_START";
const DOWNLOAD_PDF_FAILED = "DOWNLOAD_PDF_FAILED";
const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS";
const STORE_PDF_DATA = "STORE_PDF_DATA";
const CLEAR_PDF_DATA = "CLEAR_PDF_DATA";

const actions = {
  SUBMIT_ESIGN_START,
  SUBMIT_ESIGN_FAILED,
  SUBMIT_ESIGN_SUCCESS,
  IS_ON_ESIGN_PAGES,
  CURRENT_ESIGN_PAGE_INDEX,
  RESET_ESIGN_SUBMIT,
  DOWNLOAD_PDF_START,
  DOWNLOAD_PDF_FAILED,
  DOWNLOAD_PDF_SUCCESS,
  STORE_PDF_DATA,
  CLEAR_PDF_DATA,
  submitEsign: createPayloadAction(SUBMIT_ESIGN_START),
  submitEsignFailed: createEmptyAction(SUBMIT_ESIGN_FAILED),
  submitEsignSuccess: createEmptyAction(SUBMIT_ESIGN_SUCCESS),
  isOnEsignPages:  createPayloadAction(IS_ON_ESIGN_PAGES),
  currentEsignPageIndex: createPayloadAction(CURRENT_ESIGN_PAGE_INDEX),
  resetEsignSubmit: createEmptyAction(RESET_ESIGN_SUBMIT),
  downloadPdfStart: createPayloadAction(DOWNLOAD_PDF_START),
  downloadPdfFailed: createEmptyAction(DOWNLOAD_PDF_FAILED),
  downloadPdfSuccess: createEmptyAction(DOWNLOAD_PDF_SUCCESS),
  storePdfData: createPayloadAction(STORE_PDF_DATA),
  clearPdfData: createEmptyAction(CLEAR_PDF_DATA),
};

export default actions;
