export function stringIsNotNullOrEmpty(strValue) {
  return !!strValue && strValue.trim() !== "";
}

const getLastCharactersOfString = (str, count) => {
  const amountString = str.toString();
  return amountString.slice(amountString.length - count);
};

const sliceMoneySegment = (amount) => {
  const thousandthOfAmount = Math.floor(amount / 1000);
  if (thousandthOfAmount > 0) {
    const lastThreeDigits = getLastCharactersOfString(amount, 3);

    return sliceMoneySegment(thousandthOfAmount) + "," + lastThreeDigits;
  }
  return amount;
};

const getCents = (amount) => {
  const totalAmountAsCents = Math.floor(amount * 100);
  const centsWithPadding = getLastCharactersOfString(totalAmountAsCents, 2) + "00";
  const cents = centsWithPadding.slice(0, 2);

  return cents === "00" ? "" : "." + cents;
};

const createPrefix = (amount) => {
  return amount < 0 ? "- $" : "$";
}

export const formatMoney = (amount) => {
  const absoluteAmount = Math.abs(amount);
  const dollarAmount = Math.floor(absoluteAmount);
  return createPrefix(amount) + sliceMoneySegment(dollarAmount) + getCents(absoluteAmount);
};

export function isNotNullOrEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}