import defaultTheme from "./default";
import rentBondTheme from "./rentBond";
import clearScoreTheme from "./clearScore";
import overflowTheme from "./overflow";
import yesLoansTheme from "./yesLoans";
import loanOptionsTheme from "./loanOptions"
import { THEMES } from "../global/constants";
import { toLowerObjectKeyValues } from "../../utils/helpers/objectHelper";


const themes = {};
themes[THEMES.default] = defaultTheme;
themes[THEMES.rentBond] = rentBondTheme;
themes[THEMES.clearScore] = clearScoreTheme;
themes[THEMES.overflow] = overflowTheme;
themes[THEMES.yesLoans] = yesLoansTheme;
themes[THEMES.loanOptions] = loanOptionsTheme;
themes[THEMES.loading] = {};


export default themes;

const getThemeWithoutFallback = (themeName) => {
  const themeMap = {
    "rent": THEMES.rentBond,
    "clearscore": THEMES.clearScore,
    "overflow": THEMES.overflow,
    "yesloans": THEMES.yesLoans,
    "loanoptions" : THEMES.loanOptions
  };

  return themeMap[(themeName || "").toLowerCase()];
};

export const getTheme = (themeName) => getThemeWithoutFallback(themeName) || THEMES.default;

export const getThemeFromQuerystring = (queryString = {}) => {
  const toLowerQueryString = toLowerObjectKeyValues(queryString);

  const { utm_campaign, theme } = toLowerQueryString;

  return getThemeWithoutFallback(theme) || getThemeWithoutFallback(utm_campaign) || THEMES.default;
};
