import actions from "./actions";

const initState = {
  hasSigned: false,
  esignFailed: false,
  isSubmitting: false,
  isOnEsignPages: false,
  isDownloadingPdf: false,
  downloadPdfFailed: false,
  downloadPdfSuccess: false,
  pdfUrlData: [],
  currentEsignPageIndex: 0,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.SUBMIT_ESIGN_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case actions.SUBMIT_ESIGN_FAILED:
      return {
        ...state,
        isSubmitting: false,
        esignFailed: true,
      };
    case actions.SUBMIT_ESIGN_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        esignFailed: false,
        hasSigned: true,
      };
    case actions.DOWNLOAD_PDF_START:
      return {
        ...state,
        isDownloadingPdf: true,
      };
    case actions.DOWNLOAD_PDF_FAILED:
      return {
        ...state,
        isDownloadingPdf: false,
        downloadPdfFailed: true,
      };
    case actions.DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        isDownloadingPdf: false,
        downloadPdfFailed: false,
        downloadPdfSuccess: true,
      };
    case actions.IS_ON_ESIGN_PAGES:
      return {
        ...state,
        isOnEsignPages: action.payload,
      };
    case actions.CURRENT_ESIGN_PAGE_INDEX:
      return {
        ...state,
        currentEsignPageIndex: action.payload,
      };
    case actions.RESET_ESIGN_SUBMIT:
      return {
        ...state,
        hasSigned: false,
        esignFailed: false,
        isSubmitting: false,
      };
    case actions.STORE_PDF_DATA:
      return {
        ...state,
        pdfUrlData: action.payload,
      };
    case actions.CLEAR_PDF_DATA:
      return {
        ...state,
        pdfUrlData: [],
      };
    default:
      return state;
  }
}
