import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useOnMount } from "./index";
import { emptyFunction } from "../utils/helpers/emptyFunction";

const useQueryStringId = (queryStringKey) => {
  const {queryString} = useSelector(state => state.StartUp);
  const {[queryStringKey]: queryStringId} = queryString || {};
  
  return queryStringId;
};

const useLoanStateId = () => {
  const loanFormState = useSelector(state => state.loanForm);
  const {accountExternalId: loanStateId} = loanFormState;
  return loanStateId;
};

const useMissingAccountExternalIdRedirect = ({
   onMountWithId: hasIdCallback = emptyFunction, 
   queryStringKey = "id"
} = {}) => {
  const history = useHistory();
  const queryStringId = useQueryStringId(queryStringKey);
  const loanStateId = useLoanStateId();
  const id = queryStringId || loanStateId;

  useOnMount(() => {
    if (!id) {
      history.push("/");
    } else {
      hasIdCallback(id);
    }
  });
};

export default useMissingAccountExternalIdRedirect;