import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SUBMIT_ACCEPT_LINE_OF_CREDIT_START = "SUBMIT_ACCEPT_LINE_OF_CREDIT_START";
const SUBMIT_ACCEPT_LINE_OF_CREDIT_FAILED = "SUBMIT_ACCEPT_LINE_OF_CREDIT_FAILED";
const SUBMIT_ACCEPT_LINE_OF_CREDIT_SUCCESS = "SUBMIT_ACCEPT_LINE_OF_CREDIT_SUCCESS";

const actions = {
  SUBMIT_ACCEPT_LINE_OF_CREDIT_START: SUBMIT_ACCEPT_LINE_OF_CREDIT_START,
  SUBMIT_ACCEPT_LINE_OF_CREDIT_FAILED: SUBMIT_ACCEPT_LINE_OF_CREDIT_FAILED,
  SUBMIT_ACCEPT_LINE_OF_CREDIT_SUCCESS: SUBMIT_ACCEPT_LINE_OF_CREDIT_SUCCESS,
  submitAcceptLineOfCredit: createPayloadAction(SUBMIT_ACCEPT_LINE_OF_CREDIT_START),
  submitAcceptLineOfCreditFailed: createEmptyAction(SUBMIT_ACCEPT_LINE_OF_CREDIT_FAILED),
  submitAcceptLineOfCreditSuccess: createEmptyAction(SUBMIT_ACCEPT_LINE_OF_CREDIT_SUCCESS),
};

export default actions;
