import { createPayloadAction, createEmptyAction } from "../utility/actions";

const SET_STATUS_DATA = "GET_STATUS_DATA";
const FETCH_STATUS_DATA = "FETCH_STATUS_DATA";
const SUBMIT_MONEYSPOT_ENQUIRY = "SUBMIT_MONEYSPOT_ENQUIRY";
const MONEYSPOT_ENQUIRY_SUCCESS = "MONEYSPOT_ENQUIRY_SUCCESS";
const MONEYSPOT_ENQUIRY_FAILED = "MONEYSPOT_ENQUIRY_FAILED";
const FORCE_LEAD_MARKET = "FORCE_LEAD_MARKET";

const actions = {
  FETCH_STATUS_DATA,
  SET_STATUS_DATA,
  SUBMIT_MONEYSPOT_ENQUIRY,
  MONEYSPOT_ENQUIRY_FAILED,
  MONEYSPOT_ENQUIRY_SUCCESS,
  FORCE_LEAD_MARKET,
  forceLeadMarket: createEmptyAction(FORCE_LEAD_MARKET),
  moneyspotEnquirySuccess: createPayloadAction(MONEYSPOT_ENQUIRY_SUCCESS),
  moneyspotEnquiryFailed: createEmptyAction(MONEYSPOT_ENQUIRY_FAILED),
  submitMoneyspotEnquiry: createPayloadAction(SUBMIT_MONEYSPOT_ENQUIRY),
  fetchStatusData: createEmptyAction(FETCH_STATUS_DATA),
  setStatusData: createPayloadAction(SET_STATUS_DATA),
};

export default actions;
