/**
 * @description axios for fetching data via services
 */

import axios from "axios";
import apiRoutes from "./apiRoutes";
import environmentSettings from "../constants/environmentSettings";

export const apiManagement = axios.create({
  baseURL: apiRoutes.BASE_URL,
  timeout: 300000, // 5 min
  headers: {
    "Content-type": "application/json",
    accept: "application/json",
    "Ocp-Apim-Subscription-Key": environmentSettings.ocpApimSubscriptionKey
  }
});

export default apiManagement;
