import sha256 from 'crypto-js/sha256';

export const gaDataLayerPush = (email, eventName, otherProperties) => {
  const sanitizedEmail = email.trim().toLowerCase();
  const uid = sha256(sanitizedEmail).toString();
  
  const dataLayerItem = {
    ...otherProperties,
    uid,
    event: eventName,
  };
  
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(dataLayerItem);
};


export const getGoogleUserId = () => {
  const googleClientId = window.localStorage.getItem("ga.clientId");
  return googleClientId && googleClientId.trim().length > 0 ? googleClientId : null;
};