import React, { forwardRef } from "react";
import cx from "classnames";
import { StyledButton } from "./button.styles";

const Button = forwardRef(({buttonText, type, onClick, href, children, disabled, style, className, as, rel, target, elementType, variant, "aria-label": ariaLabel}, ref) => (
  <StyledButton
    className={cx(className, {disabled})}
    type={type}
    onClick={onClick}
    href={href}
    ref={ref}
    style={style}
    as={as}
    rel={rel}
    target={target}
    ariaLabel={ariaLabel}
    $elementType={elementType}
    $variant={variant}
  >
    {buttonText}
    {children}
    {!buttonText && !children ? "Button" : null}
  </StyledButton>
));

export default Button;
