import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUndefined } from "../utils/helpers/typeHelper";

const loanRouteController = ({navigators, previousMatch, setPreviousMatch, state, history, pathname, queryString}) => {
  const match = navigators.find(navigator => navigator.shouldRouteBeActive(state, pathname));
  const { utm_campaign, theme } = queryString || {};
  const cleanQueryString = { theme: (theme || utm_campaign)};

  if (!isUndefined(match)) {
    if (previousMatch !== match) {
      setPreviousMatch(match);
      match.navigate(history, state, cleanQueryString);
    }
  }
};

const useLoanRouteController = (navigators, state, isEnabled = true) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [ previousMatch, setPreviousMatch ] = useState();
  const { queryString } = useSelector(s => s.StartUp);


  useEffect(() => {
    if (isEnabled) {
      loanRouteController({navigators, previousMatch, setPreviousMatch, state, history, pathname, queryString});
    }
  }, [
    history,
    pathname,
    state,
    navigators,
    previousMatch,
    setPreviousMatch,
    queryString,
    isEnabled
  ])
};

export default useLoanRouteController;

export const __TEST__ = {
  loanRouteController,
}