import actions from "./actions";

const initState = {
  hasAccepted: false,
  acceptFailed: false,
  isAccepting: false,
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.SUBMIT_ACCEPT_LINE_OF_CREDIT_START:
      return {
        ...state,
        isAccepting: true,
      }
    case actions.SUBMIT_ACCEPT_LINE_OF_CREDIT_FAILED:
      return {
        ...state,
        isAccepting: false,
        acceptFailed: true,
        hasAccepted: false
      }
    case actions.SUBMIT_ACCEPT_LINE_OF_CREDIT_SUCCESS:
      return {
        ...state,
        isAccepting: false,
        acceptFailed: false,
        hasAccepted: true,
      }
    default:
      return state;
  }
}