import { all, takeLatest, call, put } from "redux-saga/effects";
import actions from "./actions";
import { postEsign } from "../../services/personalLoanApi";
import environmentSettings from "../../constants/environmentSettings";
import apiRoutes from "../../services/apiRoutes";
import { isNotNullOrEmpty } from "../../utils/helpers/stringHelper";

const downloadEsignPdf = (accountExternalId, docType) => {
  return fetch(
    `${environmentSettings.apiManagement}${apiRoutes.FILE_DOWNLOAD}/${accountExternalId}/${docType}?subscription-key=${environmentSettings.ocpApimSubscriptionKey}`
  )
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error('Something went wrong.');
      }
      })
    .then((blob) => window.URL.createObjectURL(blob))
    .catch((error) => console.log("error", error));
};

function* submitEsign({ payload }) {
  const { accountExternalId, smsToken } = payload;

  try {
    yield call(postEsign, accountExternalId, smsToken);
    yield put(actions.submitEsignSuccess());
    yield put(actions.currentEsignPageIndex(4));
  } catch (e) {
    yield put(actions.submitEsignFailed());
  }
}

export const __TEST__ = {
  submitEsign,
};

function* downloadPdf({ payload }) {
  const { accountExternalId } = payload;
  try {
    const contractResponse = yield call(
      downloadEsignPdf,
      accountExternalId,
      "CON"
    );
    const repaymentScheduleResponse = yield call(
      downloadEsignPdf,
      accountExternalId,
      "SCH.REPWEB"
    );
    if (
      isNotNullOrEmpty(contractResponse) &&
      isNotNullOrEmpty(repaymentScheduleResponse)
    ) {
      yield put(
        actions.storePdfData({
          contract: contractResponse,
          repayment: repaymentScheduleResponse,
        })
      );
      yield put(actions.downloadPdfSuccess());
    } else {
      yield put(actions.downloadPdfFailed());
    }
  } catch (e) {
    yield put(actions.downloadPdfFailed());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_ESIGN_START, submitEsign),
    takeLatest(actions.DOWNLOAD_PDF_START, downloadPdf),
  ]);
}
