import actions from "./actions";

const initState = {
  submitSuccessful: false,
  submitFailed: false,
  isSubmitting: false,
  nextScreen: "",
  isVerifyPath: false,
  accountExternalId: "",
  retryBankStatementPage: false,
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.SUBMIT_VERIFY_PAGE_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case actions.SUBMIT_VERIFY_PAGE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        submitFailed: true,
        submitSuccessful: false
      };
    case actions.SUBMIT_VERIFY_PAGE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submitFailed: false,
        submitSuccessful: true,
      };
    case actions.SET_VERIFY_NEXT_SCREEN:
      return {
        ...state,
        nextScreen: action.payload.nextScreen,
        submitSuccessful: false,
        submitFailed: false,
        isSubmitting: false,
      };
    case actions.SET_VERIFY_ACCOUNT_EXTERNAL_ID:
      return {
        ...state,
        accountExternalId: action.payload.accountExternalId
      };
    case actions.SET_VERIFY_PATH:
      return {
        ...state,
        isVerifyPath: true,
      };
    case actions.RESET_VERIFY_SUBMIT_STATE:
      return {
        ...state,
        submitSuccessful: false,
        submitFailed: false,
        isSubmitting: false,
      };
    case actions.RETRY_BANK_STATEMENTS:
      return {
        ...state,
        retryBankStatementPage: true,
      };
    default:
      return state;
  }
}