import useOnMount from "../../hooks/useOnMount";
import ReactModal from "react-modal";
import Wrapper from "./modal.styles";
import React from "react";

export const ModalParent = () => {
  useOnMount(() => {
    ReactModal.setAppElement("#root");
  });
  return (
    <Wrapper id="modal-parent"/>
  );
};