import { useSelector } from "react-redux";
import { THEMES } from "../../theme/global/constants";
import Loader from "./index";
import React from "react";

export const LoadingStubber = ({children}) => {
  const themeName = useSelector(state => state.StartUp.theme);
  const { isFetchingPrefill } = useSelector(state => state.loanForm);

  if (themeName === THEMES.loading || isFetchingPrefill) {
    return <Loader />;
  }

  return <>{children}</>;
};