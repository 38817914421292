const config = window.__APPFORM__.config;

const environmentSettings = {
  apiManagement: config.REACT_APP_API_MANAGEMENT,
  ocpApimSubscriptionKey: config.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  googleApiKey: config.REACT_APP_GOOGLE_API_KEY,
  leadMarketAffiliateCode: config.REACT_APP_LEAD_MARKET_AFFILIATE_CODE,
  leadMarketMode: config.REACT_APP_LEAD_MARKET_MODE,
  runConfiguration: config.REACT_APP_ENVIRONMENT_RUN_CONFIGURATION,
  bankStatementIframeUrl: config.REACT_APP_BS_IFRAME_URL,
  shouldUseAsyncQueue: config.REACT_APP_USE_QUEUE,
  fgfPortalUrl: config.FGF_PORTAL_URL,
  privacyPolicyPage: config.FGF_PRIVACY_POLICY,
  privacyPolicyPageFSA: config.FSA_PRIVACY_POLICY,
  contentManagementUrl: config.REACT_APP_CONTENT_URL,
  contentToken: config.REACT_APP_CONTENT_TOKEN,
  trustPilotDataId: config.TRUSTPILOT_WIDGET_ID,
  trustPilotBusinessUnit: config.TRUSTPILOT_WIDGET_BUSINESS_UNIT,
  trustPilotURL: config.TRUSTPILOT_URL,
};

export default environmentSettings;