import { createPayloadAction, createEmptyAction } from "../utility/actions";

const POST_LOAN_FORM_START = "POST_LOAN_FORM_START";
const POST_LOAN_FORM_SUCCESS = "POST_LOAN_FORM_SUCCESS";
const POST_LOAN_FORM_ERROR = "POST_LOAN_FORM_ERROR";
const SET_STEP = "SET_STEP";
const PREFILL_USING_PAYLOAD = "PREFILL_USING_PAYLOAD";
const RESET_FORM = "RESET_FORM";
const PREFILL_START = "PREFILL_START";
const PREFILL_SUCCESS = "PREFILL_SUCCESS";
const PREFILL_ERROR = "PREFILL_ERROR";
const SET_ALLOWED_TO_PROCEED = "SET_ALLOWED_TO_PROCEED";
const MERGE_INITIAL_FIELD_VALUE = "MERGE_INITIAL_FIELD_VALUE";
const MERGE_SANITIZED_PENDING_FIELDS = "MERGE_SANITIZED_PENDING_FIELDS";
const REMOVE_PENDING_FIELD = "REMOVE_PENDING_FIELD";
const SET_SANITIZED_QUERY_STRING_LOAN_OBJECT = "SET_SANITIZED_QUERY_STRING_LOAN_OBJECT";
const SET_HAS_FETCHED_LOAN_FORM = "SET_HAS_FETCHED_LOAN_FORM";

const actions = {
  POST_LOAN_FORM_START,
  POST_LOAN_FORM_SUCCESS,
  POST_LOAN_FORM_ERROR,
  SET_STEP,
  PREFILL_USING_PAYLOAD,
  RESET_FORM,
  PREFILL_START,
  PREFILL_SUCCESS,
  PREFILL_ERROR,
  SET_ALLOWED_TO_PROCEED,
  MERGE_INITIAL_FIELD_VALUE,
  MERGE_SANITIZED_PENDING_FIELDS,
  REMOVE_PENDING_FIELD,
  SET_SANITIZED_QUERY_STRING_LOAN_OBJECT,
  SET_HAS_FETCHED_LOAN_FORM,
  postLoanFormRequest: createPayloadAction(POST_LOAN_FORM_START),
  postLoanFormSuccess: createPayloadAction(POST_LOAN_FORM_SUCCESS),
  postLoanFormError: createPayloadAction(POST_LOAN_FORM_ERROR),
  prefillStart: createEmptyAction(PREFILL_START),
  prefillSuccess: createEmptyAction(PREFILL_SUCCESS),
  prefillError: createPayloadAction(PREFILL_ERROR),
  setAllowedToProceed: createPayloadAction(SET_ALLOWED_TO_PROCEED),
  setStep: createPayloadAction(SET_STEP),
  resetForm: createEmptyAction(RESET_FORM),
  setFieldInitialValue: createPayloadAction(MERGE_INITIAL_FIELD_VALUE),
  mergeSanitizedPendingFields: createPayloadAction(MERGE_SANITIZED_PENDING_FIELDS),
  setSanitizedQueryStringLoanObject: createPayloadAction(SET_SANITIZED_QUERY_STRING_LOAN_OBJECT),
  removePendingField: createPayloadAction(REMOVE_PENDING_FIELD),
  setHasFetchedLoanForm: createEmptyAction(SET_HAS_FETCHED_LOAN_FORM),
  prefillUsingPayload: (value) => {
    const defaultStep = 1;
    
    const stepMap = {
      "Screen 1 - About You": 2,
      "Screen 2 - Your Living Situation": 3,
      "Screen 3 - Your Livelihood & Income": 4,
      "Screen 4 - Bank Statements": 5,
    };
        
    const step = stepMap[value.source] || defaultStep;
        
    const legacySourcePrefill = step === defaultStep;
    return createPayloadAction(PREFILL_USING_PAYLOAD)({value, step, legacySourcePrefill});
  }
};

export default actions;
