import actions from "./actions";
import { STEPS_TOTAL } from "../../constants/commonConstants";
import { prepareLoanForm } from "../../services/prepareLoanForm";

const initialStep = 1;
const initState = {
  accountExternalId: null,
  isFetching: null,
  hasFetchedLoanForm: false,
  isFetchingPrefill: false,
  loanFormResponse: null,
  legacySourcePrefill: false,
  loanFormError: null,
  initialValue: prepareLoanForm({ values: {}, step: initialStep }),
  pendingFields: {},
  sanitizedQuerystring: {},
  step: initialStep,
  isFormCompleted: false,
  loanStatus: null,
  verify: {
    nextScreen: "",
  },
  allowedToProceed: setAllowedToProceed(true)
};

function constrainStep(stepToSet) {
  const totalSteps = STEPS_TOTAL;
  return Math.min(stepToSet, totalSteps);
}

function getCurrentLoanState(state) {
  return state.loanFormResponse ? {...state.initialValue, ...state.loanFormResponse.value} : state.initialValue ?? {};
}

function setAllowedToProceed(isAllowedToProceed, message, action) {
  return {
    isAllowedToProceed,
    message,
    action
  };
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.POST_LOAN_FORM_START:
      return {
        ...state,
        isFetching: true,
        loanFormResponse: null,
        loanFormError: null
      };
    case actions.POST_LOAN_FORM_SUCCESS:
      const { data, isFormCompleted } = action.payload;
      return {
        ...state,
        isFetching: false,
        loanFormResponse: data,
        accountExternalId: data.value.accountExternalId,
        loanFormError: null,
        isFormCompleted
      };
    case actions.POST_LOAN_FORM_ERROR:
      return {
        ...state,
        isFetching: false,
        loanFormResponse: null,
        loanFormError: action.payload
      };
    case actions.SET_STEP:
      return {
        ...state,
        step: constrainStep(action.payload),
        initialValue: prepareLoanForm({
          values: getCurrentLoanState(state),
          step: action.payload
        })
      };
    case actions.PREFILL_USING_PAYLOAD:
      return {
        ...state,
        step: action.payload.step,
        accountExternalId: action.payload.value.accountExternalId,
        initialValue: prepareLoanForm({
          values: {
            ...state.initialValue,
            ...action.payload.value
          },
          step: action.payload.step,
        }),
        verify: action.payload.value.verify,
        legacySourcePrefill: action.payload.legacySourcePrefill,
      };
    case actions.RESET_FORM:
      return {
        ...initState
      };
    case actions.PREFILL_START:
      return {
        ...state,
        isFetchingPrefill: true,
      };
    case actions.PREFILL_SUCCESS:
    return {
      ...state,
      isFetchingPrefill: false,
    };
    case actions.PREFILL_ERROR:
      return {
        ...state,
        loanStatus: action.payload,
        isFetchingPrefill: false,
      };

    case actions.SET_ALLOWED_TO_PROCEED:
      return {
        ...state,
        allowedToProceed: setAllowedToProceed(
          action.payload.allowedToProceed,
          action.payload.message,
          action.payload.action)
      };

    case actions.MERGE_INITIAL_FIELD_VALUE:
      return {
        ...state,
        initialValue: prepareLoanForm({
          values: {
            ...state.initialValue,
            ...action.payload,
          },
          step: state.step
        }),
      };
    case actions.MERGE_SANITIZED_PENDING_FIELDS:
      return {
        ...state,
        pendingFields: {
          ...state.pendingFields,
          ...action.payload,
        }
      };
    case actions.SET_SANITIZED_QUERY_STRING_LOAN_OBJECT:
      return {
        ...state,
        sanitizedQuerystring: action.payload
      }
    case actions.REMOVE_PENDING_FIELD:
      const newPendingFields = { ...state.pendingFields };
      delete newPendingFields[action.payload];
      const pendingFields = state.pendingFields.hasOwnProperty(action.payload) ? newPendingFields : state.pendingFields;

      return {
        ...state,
        pendingFields,
      }
    case actions.SET_HAS_FETCHED_LOAN_FORM:
      return {
        ...state,
        hasFetchedLoanForm: true,
      };
    default:
      return state;
  }
}

export const __TEST__ = {
  initState,
};
