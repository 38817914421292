import { isNotNullOrEmpty, stringIsNotNullOrEmpty } from "./stringHelper";
import { TRUE_FALSE_VALUES } from "../../constants/commonConstants";

const setTrueFalseValues = (formKeyValues, key, value) => {
  if (value === true || value === "true") {
    formKeyValues[key] = TRUE_FALSE_VALUES.TRUE;
  } else {
    formKeyValues[key] = TRUE_FALSE_VALUES.FALSE;
  }
}

const setTrueFalseValue = (formKeyValues, key) => {
  const partnerIncomeValue = formKeyValues[key];
  if (isNotNullOrEmpty(partnerIncomeValue)) {
    setTrueFalseValues(formKeyValues, key, partnerIncomeValue);
  }
};

const mapValue = (formKeyValues, key, incorrectValue, correctValue) => {
  const loanReasonValue = formKeyValues[key];
  if (stringIsNotNullOrEmpty(loanReasonValue) && loanReasonValue === incorrectValue) {
    formKeyValues[key] = correctValue;
  }
};

export const sanitizeValues = formKeyValues => {
  mapValue(formKeyValues, "loanReason", "BondLoan", "Bond Loan");
  mapValue(formKeyValues, "residentialStatus", "Home owner", "Homeowner");
  mapValue(formKeyValues, "residentialStatus", "Public housing", "Renting");
  mapValue(formKeyValues, "residentialStatus", "Private renter", "Renting");
  mapValue(formKeyValues, "residentialStatus", "With parents", "Boarding");
  mapValue(formKeyValues, "employmentBasis", "FT", "Full Time Work");
  mapValue(formKeyValues, "employmentBasis", "PT", "Part Time Work");
  mapValue(formKeyValues, "employmentBasis", "Casual", "Casual Work");
  mapValue(formKeyValues, "employmentBasis", "Retired", "Other");
  mapValue(formKeyValues, "employmentBasis", "Student", "Other");
  mapValue(formKeyValues, "employmentBasis", "Home maker", "Other");
  setTrueFalseValue(formKeyValues, "partnerIncome");
  setTrueFalseValue(formKeyValues, "foreseeableChanges");  

  return {
    ...formKeyValues
  };
};
