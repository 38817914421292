import { all, takeLatest, call, put } from "redux-saga/effects";
import actions from "./actions";
import { postAcceptLiftLoan } from "../../services/personalLoanApi";

function* submitAcceptLiftLoan({payload}) {
  const { accountExternalId } = payload;
  
  try {
    yield call(postAcceptLiftLoan, accountExternalId);
    yield put(actions.submitAcceptLiftLoanSuccess());
  } catch(e) {
    yield put(actions.submitAcceptLiftLoanFailed());
  }
}

export const __TEST__ = {
  submitAcceptLiftLoan
};

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_ACCEPT_START, submitAcceptLiftLoan),
  ]);
}
