import { all, takeLatest, call, put, delay } from "redux-saga/effects";
import actions from "./actions";
import { postVerify } from "../../services/personalLoanApi";

function* submitVerifyPage({payload}) {
  const { accountExternalId, stepName, data } = payload;
  let response;

  yield put(actions.submitVerifyPageStart());
  yield put(actions.setVerifyPath());
  
  try {
    response = yield call(postVerify, accountExternalId, stepName, data);
  }
  catch (e) {
    response = e.response;
  }

  const { status, data: responseData } = response;
  if (status === 200) {
    const { value } = responseData || {};
    const { nextScreen } = value || {};
    yield put(actions.submitVerifyPageSuccess());
    yield delay(2000);
    let action = actions.setVerifyNextScreen({nextScreen});
    yield put(action);
  } else {
    yield put(actions.submitVerifyPageFailed());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_VERIFY_PAGE, submitVerifyPage),
  ]);
}

export const __TEST__ = {
  submitVerifyPage,
};
