import { isUndefined } from "lodash";
import {apiManagement} from "./apiManagement";
import apiRoutes, { referMeMoneyspot } from "./apiRoutes";
import { shouldUseAsyncQueue } from "../utils/helpers/environment";

export const getLoanAmount = () => 
  apiManagement.get(apiRoutes.LOAN_AMOUNT);

export const getAutoLoanAmount = () => 
  apiManagement.get(apiRoutes.AUTO_LOAN_AMOUNT);

export const getLoanReasons = () => 
  apiManagement.get(apiRoutes.LOAN_REASONS);

const getApplicationEndpoint = (useQueue) => {
  const validUseQueueValues = ["true", "false"];
  if (!isUndefined(useQueue) && validUseQueueValues.includes(useQueue)) {
    return useQueue === "true" ? apiRoutes.APPLICATION_QUEUE_ENDPOINT : apiRoutes.APPLICATION_ENDPOINT;
  }

  return shouldUseAsyncQueue() ? apiRoutes.APPLICATION_QUEUE_ENDPOINT : apiRoutes.APPLICATION_ENDPOINT;
};

export const postLoanForm = (payload, search, googleUserId, useQueue) => {
  const queryString = (search || "").length === 0 ? "" : search;
  
  const headers = googleUserId !== null ? {"X-Google-Client-Id": googleUserId} : {};
  return apiManagement.post(getApplicationEndpoint(useQueue) + queryString, { ...payload }, {headers});
};

export const postBankStatement = (loanId, bankStatementsReferralCode) => {
  const queryString = `?id=loanapplication-${loanId}&bankStatementsReferralCode=${bankStatementsReferralCode}`
  return apiManagement.post(apiRoutes.SUBMIT_BANKSTATEMENTS_ENDPOINT + queryString);
};

export const getLoanForm = (id) => apiManagement.get(apiRoutes.APPLICATION_ENDPOINT + `?id=${id}`);

export const postVerify = (accountExternalId, stepName, data) =>
  apiManagement.post(`${apiRoutes.APPLICATION_ENDPOINT}/${accountExternalId}/verification/${stepName}`, data);

export const postEsign = (accountExternalId, smsToken) => 
  apiManagement.post(apiRoutes.ESIGN_ENDPOINT, {accountExternalId, smsToken})

export const postAcceptLiftLoan = (accountExternalId) => {
  const queryString = `?externalid=${accountExternalId}`
  return apiManagement.post(apiRoutes.ACCEPT_LIFT_LOAN_ENDPOINT + queryString)
}

export const postAcceptLineOfCredit = (accountExternalId) => {
  const queryString = `?accountExternalId=${accountExternalId}`
  return apiManagement.post(apiRoutes.ACCEPT_LINE_OF_CREDIT_ENDPOINT + queryString)
}

export const postAcceptAutoFinance = (accountExternalId) => {
  const queryString = `?externalId=${accountExternalId}`
  return apiManagement.post(apiRoutes.ACCEPT_AUTO_FINANCE_ENDPOINT + queryString)
}

export const getApplicationStatus = (accountExternalId) => {
  return apiManagement.get(apiRoutes.APPLICATION_STATUS_ENDPOINT + `?id=${accountExternalId}`);
}

export const postReferMeMoneyspot = (accountExternalId) => {
  return apiManagement.post(referMeMoneyspot(accountExternalId));
}