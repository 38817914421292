import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from './theme/global/globalStyles';
import store from './redux/store';
import Boot from './redux/boot';
import Routes from './routes';
import AppProvider from './AppProvider';
import { ModalParent } from "./components/modal";
import useScript from './hooks/useScript';
import { isProduction } from "./utils/helpers/environment";
import { MapsApiScriptElement } from "./components/mapsApiScriptElement";

const App = () => {
  useScript('//js.hs-scripts.com/7705693.js', "hs-script-loader", isProduction(), true);
  useScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js', "hs-script-loader", true, true);

  return (
    <Provider store={store}>
      <AppProvider>
        <MapsApiScriptElement />
        <GlobalStyles />
        <Routes />
        <ModalParent />
      </AppProvider>
    </Provider>
  );
};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;