/**
 * @description Common Saga combined
 */

import { all } from "redux-saga/effects";
import startUpSagas from "./startup/sagas";
import loanFormSagas from "./loanForm/sagas";
import esignSagas from "./esign/sagas";
import acceptLiftLoanSagas from "./acceptLiftLoan/sagas";
import acceptLineOfCreditSagas from "./acceptLineOfCredit/sagas";
import acceptAutoFinanceSagas from "./acceptAutoFinance/sagas";
import verifySagas from "./verify/sagas";
import trackingStatusSagas from "./statusTracking/sagas";
import contentDeliverySagas from "./contentDelivery/sagas";

export default function* rootSaga(getState) {
  yield all([
    startUpSagas(getState), 
    loanFormSagas(getState), 
    esignSagas(getState), 
    acceptLiftLoanSagas(getState), 
    acceptLineOfCreditSagas(getState),
    acceptAutoFinanceSagas(getState),
    verifySagas(getState),
    trackingStatusSagas(getState),
    contentDeliverySagas(getState),
  ]);
}
