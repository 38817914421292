import { all, call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import {
  getApplicationStatus,
  postReferMeMoneyspot,
} from "../../services/personalLoanApi";
import { externalId } from "./selectors";

function* fetchTrackingStatus() {
  let applicationId = yield select(externalId);
  try {
    const response = yield call(getApplicationStatus, applicationId);
    const { data } = response;
    const { code, errors, value } = data;
    if (code === 200) {
      yield put(actions.setStatusData(value));
    } else {
      console.log(errors);
    }
  } catch (error) {
    console.log("No valid application ID found");
  }
}

function* fetchMoneyspotUrl() {
  let applicationId = yield select(externalId);
  try {
    const response = yield call(postReferMeMoneyspot, applicationId);
    const { data } = response;
    const { code, errors, value } = data;
    if (code === 200) {
      yield put(actions.moneyspotEnquirySuccess(value));
      if (value.url === null) {
        yield put(actions.forceLeadMarket());
      }
    } else {
      console.log(errors);
    }
  } catch (err) {
    console.log("An error has occurred");
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_STATUS_DATA, fetchTrackingStatus)]);
  yield all([takeLatest(actions.SUBMIT_MONEYSPOT_ENQUIRY, fetchMoneyspotUrl)]);
}
