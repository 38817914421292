import { sanitizeValues } from "../utils/helpers/sanitizeLoanObjectValues";

export const prepareLoanForm = ({values = {}, step} = {}) => {
  const sourceMap = {
    1: "Screen 1 - About You",
    2: "Screen 2 - Your Living Situation",
    3: "Screen 3 - Your Livelihood & Income",
    4: "Screen 4 - Bank Statements",
  }

  const source = sourceMap[step];
  const sanitizedValues = sanitizeValues(values)

  return {...sanitizedValues, source};
};