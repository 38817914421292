const toLowerCaseFunc = str => typeof str === "string" ? str.toLowerCase() : str;

const returnerFunc = arg => arg;

const mapKeyValues = ({keyMapper = returnerFunc, valueMapper = returnerFunc}) => objectToMap =>
  Object
    .entries(objectToMap)
    .reduce(
      (accumulator, [key, value]) => {
        accumulator[keyMapper(key)] = valueMapper(value);
        return accumulator;
      }, {}
    );

export const toLowerObjectKeyValues = mapKeyValues({keyMapper: toLowerCaseFunc, valueMapper: toLowerCaseFunc});

export const toLowerObjectKeys = mapKeyValues({keyMapper: toLowerCaseFunc});