import { useRef, useState } from "react";
import { useOnDismount, useOnMount } from "./index";

const focusEventName = "focus";
const blurEventName = "blur";

const useFocus = (defaultState = false) => {
  const ref = useRef();
  const [state, setState] = useState(defaultState);

  const onFocus = () => setState(true);
  const onBlur = () => setState(false);

  useOnMount(() => {
    ref.current.addEventListener(focusEventName, onFocus);
    ref.current.addEventListener(blurEventName, onBlur);
  });
  
  useOnDismount(() => {
    ref.current.removeEventListener(focusEventName, onFocus);
    ref.current.removeEventListener(blurEventName, onBlur);
  });
  
  return [ref, state];
};

export default useFocus;