import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader, { LoadingStubber } from "./components/loader";
import { Redirect } from "./components/fairGoRedirect/fairGoRedirect";

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: false,
    component: lazy(() => import("./containers/PersonalLoan/containers/PersonalLoan"))
  },
  {
    path: PUBLIC_ROUTE.STATUS,
    exact: false,
    component: lazy(() => import("./containers/Tracking/tracking"))
  },
  {
    path: PUBLIC_ROUTE.VERIFY,
    exact: false,
    component: lazy(() => import("./containers/Verify/Verify"))
  },
  {
    path: PUBLIC_ROUTE.ESIGN,
    exact: true,
    component: lazy(() => import("./containers/Esign/Esign"))
  },
  {
    path: PUBLIC_ROUTE.ACCEPT_AUTO_FINANCE,
    exact: true,
    component: lazy(() => import("./containers/AcceptAutoFinance/AcceptAutoFinance"))
  },
  {
    path: PUBLIC_ROUTE.ACCEPT_LIFT_LOAN,
    exact: true,
    component: lazy(() => import("./containers/AcceptLiftLoan/AcceptLiftLoan"))
  },
  {
    path: PUBLIC_ROUTE.ACCEPT_LINE_OF_CREDIT,
    exact: true,
    component: lazy(() => import("./containers/AcceptLineOfCredit/AcceptLineOfCredit"))
  },
  {
    path: PUBLIC_ROUTE.BANK_STATEMENT_PAGE,
    exact: true,
    component: lazy(() => import("./containers/BankStatements/BankStatements"))
  },
  {
    path: PUBLIC_ROUTE.DECLINE,
    exact: false,
    component: lazy(() => import("./containers/DeclineRouter/DeclineRouter"))
  },
  {
    path: PUBLIC_ROUTE.THANK_YOU,
    exact: true,
    component: lazy(() => import("./containers/ThankYou/index"))
  },
  {
    path: PUBLIC_ROUTE.PAGE_NOT_FOUND,
    component: lazy(() => import("./containers/404/404"))
  },
];

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <BrowserRouter> 
          <Switch>
            <Route path={PUBLIC_ROUTE.INDEX} exact={true}>
              <Redirect pathname={PUBLIC_ROUTE.LANDING} withSearch />
            </Route>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <LoadingStubber>
                  <route.component />
                </LoadingStubber>
              </Route>
            ))}
          </Switch>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
}
