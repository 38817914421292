export const PUBLIC_ROUTE = {
  INDEX: "/",
  LANDING: "/apply",
  LIVELIHOOD_INCOME: "/livelihood-income",
  LIVING_SITUATION: "/living-situation",
  BANK_STATEMENT_PAGE: "/bank-statements",
  COMING_SOON: "/coming-soon",
  ESIGN: "/esign",
  ACCEPT_LIFT_LOAN: "/accept-lift-loan",
  ACCEPT_LINE_OF_CREDIT: "/accept-line-of-credit",
  ACCEPT_AUTO_FINANCE: "/accept-auto-finance",
  DECLINE: "/decline",
  DECLINED_IN_PROGRESS: "/decline/in-progress",
  APPLICATION_NOT_FOUND: "/decline/application-not-found",
  APPLICATION_EXPIRED: "/decline/application-expired",
  THANK_YOU:"/thank-you",
  VERIFY: "/verify",
  STATUS: "/status",
  PAGE_NOT_FOUND: "",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/"
};
