import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SUBMIT_VERIFY_PAGE = "SUBMIT_VERIFY_PAGE";
const SUBMIT_VERIFY_PAGE_START = "SUBMIT_VERIFY_PAGE_START";
const SUBMIT_VERIFY_PAGE_FAILED = "SUBMIT_VERIFY_PAGE_FAILED";
const SUBMIT_VERIFY_PAGE_SUCCESS = "SUBMIT_VERIFY_PAGE_SUCCESS";
const SET_VERIFY_NEXT_SCREEN = "SET_VERIFY_NEXT_SCREEN";
const RESET_VERIFY_SUBMIT_STATE = "RESET_VERIFY_SUBMIT_STATE";
const SET_VERIFY_ACCOUNT_EXTERNAL_ID = "SET_VERIFY_ACCOUNT_EXTERNAL_ID";
const RETRY_BANK_STATEMENTS = "RETRY_BANK_STATEMENTS";
const SET_VERIFY_PATH = "SET_VERIFY_PATH";

const actions = {
  SUBMIT_VERIFY_PAGE,
  SUBMIT_VERIFY_PAGE_START,
  SUBMIT_VERIFY_PAGE_FAILED,
  SUBMIT_VERIFY_PAGE_SUCCESS,
  SET_VERIFY_NEXT_SCREEN,
  RESET_VERIFY_SUBMIT_STATE,
  SET_VERIFY_ACCOUNT_EXTERNAL_ID,
  RETRY_BANK_STATEMENTS,
  SET_VERIFY_PATH,
  setVerifyPath: createEmptyAction(SET_VERIFY_PATH),
  submitVerifyPage: createPayloadAction(SUBMIT_VERIFY_PAGE),
  submitVerifyPageStart: createEmptyAction(SUBMIT_VERIFY_PAGE_START),
  submitVerifyPageFailed: createEmptyAction(SUBMIT_VERIFY_PAGE_FAILED),
  submitVerifyPageSuccess: createEmptyAction(SUBMIT_VERIFY_PAGE_SUCCESS),
  setVerifyNextScreen: createPayloadAction(SET_VERIFY_NEXT_SCREEN),
  setVerifyExternalId: createPayloadAction(SET_VERIFY_ACCOUNT_EXTERNAL_ID),
  resetVerifySubmitState: createEmptyAction(RESET_VERIFY_SUBMIT_STATE),
  retryBankStatements: createEmptyAction(RETRY_BANK_STATEMENTS),
};

export default actions;
