import React from "react";
import LoaderComponent from "./loader.style";

export default function Loader({ ...props }) {
  const { type, loading } = props;
  
  const color = {
    circle: "greenCirle",
    label: "greenLabel"
  };
  if (type === "light") {
    color.circle = "whiteCirle";
    color.label = "whiteLabel";
  }
  
  return (
    loading !== false && (
      <LoaderComponent notFullPageLoader={props.notFullPageLoader}>
        <svg data-testid="loader" className="isoContentLoader" viewBox="0 0 50 50">
          <circle
            className={`isoContentLoaderCircle ${color.circle}`}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="3.6"
          />
        </svg>
      </LoaderComponent>
    )
  );
}
