import { all, takeLatest, call, put } from "redux-saga/effects";
import actions from "./actions";
import { postAcceptLineOfCredit } from "../../services/personalLoanApi";

function* submitAcceptLineOfCredit({payload}) {
  const { accountExternalId } = payload;
  
  try {
    yield call(postAcceptLineOfCredit, accountExternalId);
    yield put(actions.submitAcceptLineOfCreditSuccess());
  } catch(e) {
    yield put(actions.submitAcceptLineOfCreditFailed());
  }
}

export const __TEST__ = {
  submitAcceptLineOfCredit
};

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_ACCEPT_LINE_OF_CREDIT_START, submitAcceptLineOfCredit),
  ]);
}
